// JsFromRoutes CacheKey 53bce75837c117e7101e9a2033d8fe27
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  finish: definePathHelper('post', '/documents/finish'),
  entries: definePathHelper('get', '/documents/:id/entries'),
  state: definePathHelper('get', '/documents/:id/state'),
  updateNotifications: definePathHelper('post', '/documents/:id/update_notifications'),
}
